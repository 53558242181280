
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar
} from '@ionic/vue'
import { 
  defineComponent, 
  reactive, 
  ref,
  computed, 
  onMounted,
  onUpdated,
  onBeforeUpdate
} from 'vue'
import { add, createOutline, trashOutline, chevronDownCircleOutline, filterOutline } from 'ionicons/icons'
import { useRouter } from 'vue-router'
import "firebase/firestore";
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import ReportFilter from '@/components/ReportFilter.vue'
import useFirebaseFirestore from "../hooks/firebase-firestore"

export default defineComponent({
  name: 'ExpensesPage',
  components: {
    IonCard,
    IonCardContent,
    IonContent,
    IonFab,
    IonFabButton,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    AppHeader,
    AppFooter,
    ReportFilter
  },
  setup() {
    const isFilterOpenRef = ref(false)
    const setFilterOpen = (state: any) => isFilterOpenRef.value = state

    const reportState = reactive ({
      selectedBookId: null
    })

    const reportFormAction = { 
      formType: null,
      type: 'report',
      categoryLists: {},
      filterData: {
        fromDate: '',
        toDate: '',
        category: []
      },
      formDetail: {}
    }

    const selectedBookId = async () => {
      // const selectedBook: any = await localStorage.getItem('selectedBook')
      // console.log(JSON.parse(selectedBook).id)
      // reportState.selectedBookId = JSON.parse(selectedBook).id
      const selectedBook: any = await useFirebaseFirestore().selectedBookId
      reportState.selectedBookId = selectedBook
      return reportState.selectedBookId
    }
    const collectionType = "reports"

    const sortOptions = {
      by: "createdAt",
      rule: "desc"
    }
    
    const today = new Date();
    const filterOptions = {
      fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
      toDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      category: []
    }

    const categoryLists = async () => {
      const localSelectedBooks: any = await localStorage.getItem('selectedBook')
      const selectedBooks = JSON.parse(localSelectedBooks)
      reportFormAction.categoryLists = selectedBooks.settings.categories
      // return selectedBooks
      // filterOptions.category =  selectedBooks.settings.categories.income.concat(selectedBooks.settings.categories.expense)
      // filterOptions.category.push(selectedBooks.settings.categories.expense)
      // console.log(incomeFormAction.formDetail.categoryLists)
    }

    const reportData = reactive <Map<string, any>>(new Map<string, any>())

    const displayReportData = computed(() => Array.from(reportData.values()))

    
    const finalBalance = computed (() => {
      const incomeNominal = displayReportData.value.map( (item) => (item.dataType == 'income') ? item.nominal : 0)
      const expenseNominal = displayReportData.value.map( (item) => (item.dataType == 'expense') ? item.nominal : 0)
      const totalIncome = incomeNominal.reduce( (accumulator, item) => {
        return accumulator + item;
      }, 0)
      const totalExpense = expenseNominal.reduce( (accumulator, item) => {
        return accumulator + item;
      }, 0)
      return {
        income: totalIncome,
        expense: totalExpense,
        total: totalIncome - totalExpense
      }
    })

    // const incomeSum = computed(() => {
      //   displayReportData.value.reduce( (item., currentValue) => accumulator + currentValue )
    // })
    // console.log(incomeSum)
    
    const displayDate = (transactionDate: any) =>  transactionDate.toDate().toDateString()
    const displayNominals = (nominal: number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(nominal)

    const getReportData = async () => {
      reportData.clear()
      const queryReportData = await useFirebaseFirestore()
          .getItem(collectionType, sortOptions, filterOptions);
      if (queryReportData.status == 1) {
        queryReportData.data.forEach( (doc: any) => {
          const itemId = doc.id
          reportData.set(itemId, {
            ...doc.data(),
            id: itemId,
          })
        })
      }
      // console.log(reportData)
    }

    const checkBookId = async () => {
      // console.log('Last BookID: ' + reportState.selectedBookId)
      const currentBook: any = await localStorage.getItem('selectedBook')
      const currentBookId = JSON.parse(currentBook).id
      // console.log('current BookID: ' + currentBookId)
      if (currentBookId !== reportState.selectedBookId) {
        // console.log('Selected Book ID different')
        // console.log('New BookId: ' + reportState.selectedBookId)
        // console.log('Need Update Report Data')
        reportState.selectedBookId = currentBookId
        await getReportData()
        // return {status: 1, message: 'Need Update'}
      } else if (!displayReportData.value.length) {
        await getReportData()
      } else {
        // console.log('Selected Book ID same')
        // console.log('No Need Update Data')
        const needUpdate: any = await localStorage.getItem('needUpdateReportList')
        if (needUpdate == 1) {
          // console.log('There is Need Update Report List')
          await getReportData()
          localStorage.setItem('needUpdateReportList', "0")
        }
        // return {status: 0, message: 'No Need Update'}
      }
    }

    const doRefresh = (event: any) => {
      // console.log('Begin async operation');

      setTimeout(async () => {
        // console.log('Async operation has ended');
        await getReportData()
        event.target.complete();
      }, 5000);
    }

    const filterData = async (event: any) => {
      // console.log(event.action)
      if (event.action == 'filter') {
        filterOptions.fromDate = event.formData.filterFromDate
        filterOptions.toDate = event.formData.filterToDate
        filterOptions.category = event.formData.filterCategory
        reportFormAction.filterData.fromDate = event.formData.filterFromDate
        reportFormAction.filterData.toDate = event.formData.filterToDate
        reportFormAction.filterData.category = event.formData.filterCategory
        setFilterOpen(false)
        await getReportData()
      }
    }

    const closeFilterData = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setFilterOpen(false) : null
    }

    onMounted( async () => {
      await selectedBookId()
      await categoryLists()
      await getReportData()
    })

    onBeforeUpdate ( () => {
      // console.log('on Befreupdate')
      setFilterOpen(false)
    })

    onUpdated( async () => {
      // console.log('updated!')
      // const checkBookId = await useGlobalHelpers()
      //       .checkBookId(state.selectedBookId)
      // console.log(checkBookId)
      // if (checkBookId.status == 1) {
      //   await getReportData()
      // }
      await checkBookId()
      await categoryLists()
      // await getReportData()
    })

    return {
      //function
      ...reportState,
      isFilterOpenRef,
      setFilterOpen,
      router: useRouter(),
      displayReportData,
      getReportData,
      finalBalance,
      selectedBookId,
      // checkBookId,
      doRefresh,
      categoryLists,
      filterData,
      closeFilterData,
      
      //properties
      reportFormAction,
      displayDate,
      displayNominals,
      sortOptions,

      //icon
      add,
      createOutline,
      trashOutline,
      chevronDownCircleOutline,
      filterOutline
    }
  }
})
